import type { AppProps } from "next/app";
import Head from "next/head";
import Script from "next/script";
import "./globals.css";
import "../styles/globals.scss";
import { useState, useEffect } from "react";
import { useRouter } from "next/router";
import { AuthProvider } from "@/app/contexts/AuthContext";
import HeaderCMS from "@/app/components/UI/HeaderCMS";
import { actualSite, BRAND_NAMES } from "@/app/utils/utils";
import DefaultLayout from "@/app/components/LayoutComponents";
import { FormVendaContext } from "@/app/components/formContext/formcontext";
import GoogleTagManager from "@/app/components/GoogleTagManager";
import { pagesData } from "@/app/utils/metadata";

// Tipagem para os pageProps que incluem os dados do banner
interface CustomPageProps {
  contents?: string[];
  title_contents?: string[];
  [key: string]: string[] | undefined;
}

interface PageMetadata {
  pagina: string;
  title: string;
  description: string;
}

function MyApp({ Component, pageProps }: AppProps<CustomPageProps>) {
  const [siteConfig, setSiteConfig] = useState<
    ReturnType<typeof actualSite> | undefined
  >(undefined);
  const [newBanner, setNewBanner] = useState(false);
  const [isClient, setIsClient] = useState(false);
  const [pageMetadata, setPageMetadata] = useState<PageMetadata>({
    pagina: "/",
    title: BRAND_NAMES.META_TITLE,
    description: `O seu carro 0km está na ${BRAND_NAMES.DEFAULT} Toyota! SUV, sedan, hatch e picape com as melhores condições e ofertas imperdíveis. Confira!`,
  });
  const router = useRouter();
  const cmsPath = "/cms";

  // Função para obter os metadados da página atual
  const getPageMetadata = (path: string): PageMetadata => {
    const pageData = pagesData.find(
      (page: PageMetadata) => page.pagina === path
    );
    return pageData || pagesData[0]; // Retorna os metadados da home se não encontrar
  };

  useEffect(() => {
    const config = actualSite();
    setSiteConfig(config);
    setIsClient(true);

    // Configura os metadados iniciais
    const metadata = getPageMetadata(router.pathname);
    setPageMetadata(metadata);

    // Listener para mudanças de rota
    const handleRouteChange = (url: string) => {
      const formattedUrl = url.split("?")[0]; // Remove query params
      const newMetadata = getPageMetadata(formattedUrl);
      setPageMetadata(newMetadata);
    };

    router.events.on("routeChangeComplete", handleRouteChange);

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router]);

  if (!siteConfig) return null;

  // Verifica se é a página do linktree
  if (router.pathname === "/linktree") {
    return <Component {...pageProps} />;
  }

  return (
    <>
      <Head>
        <title>{pageMetadata.title}</title>
        <meta charSet="utf-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta name="description" content={pageMetadata.description} />
        <meta property="og:type" content="website" />
        <link rel="canonical" href={`https://www.${BRAND_NAMES.URL}.com.br`} />
        <meta
          name="keywords"
          content={`toyota, ${BRAND_NAMES.DEFAULT}, concessionária toyota, carros novos, carros usados, seminovos, serviços toyota, ${BRAND_NAMES.STATE}`}
        />
        <meta name="author" content={`${BRAND_NAMES.DEFAULT} Toyota`} />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=2, user-scalable=yes, viewport-fit=cover"
        />
      </Head>

      <GoogleTagManager />
      {router.asPath.includes(cmsPath) ? (
        <AuthProvider>
          {isClient &&
            router.pathname !== cmsPath &&
            window?.location?.hostname !== `www.${BRAND_NAMES.URL}.com.br` && (
              <HeaderCMS setNewBanner={setNewBanner} />
            )}
          <Component
            newBanner={newBanner}
            setNewBanner={setNewBanner}
            {...pageProps}
          />
        </AuthProvider>
      ) : (
        <FormVendaContext>
          <DefaultLayout
            contents={pageProps.contents}
            title_contents={pageProps.title_contents}
          >
            <Component {...pageProps} />
          </DefaultLayout>
        </FormVendaContext>
      )}
    </>
  );
}

export default MyApp;
