import { useEffect } from "react";
import TagManager from "react-gtm-module";

const GoogleTagManager = () => {
  useEffect(() => {
    const gtmId = "GTM-W5K92B2";

    TagManager.initialize({ gtmId });
  }, []);

  return null; // Não renderiza nada
};

export default GoogleTagManager;
