import { actualSite, BRAND_NAMES } from "@/app/utils/utils";

export interface LinksNavigation {
  name: string;
  src: string;
  groups: {
    name: string;
    itens: {
      name: string;
      link: string;
      src?: string;
      target?: string;
    }[];
  }[];
}

const { siteDefaultName } = actualSite();

export const NavigationLines: LinksNavigation[] = [
  {
    name: "Novos",
    src: "https://sablobsitestoyotaprd.blob.core.windows.net/images/Shared/Header/novos.webp",
    groups: [
      {
        name: "Passeio",
        itens: [
          {
            name: "Yaris",
            src: "https://sablobsitestoyotaprd.blob.core.windows.net/images/Shared/Header/mbl/Yaris_Hatch.png",
            link: "/yaris",
          },
          {
            name: "Yaris Sedan",
            src: "https://sablobsitestoyotaprd.blob.core.windows.net/images/Shared/Header/mbl/Yaris_Sedan.png",
            link: "/yaris?model=Sedan",
          },
          {
            name: "Corolla",
            src: "https://sablobsitestoyotaprd.blob.core.windows.net/images/Shared/Header/mbl/Corolla_Altis.png",
            link: "/corolla",
          },
        ],
      },
      {
        name: "SUVs",
        itens: [
          {
            name: "Corolla Cross",
            src: "https://sablobsitestoyotaprd.blob.core.windows.net/images/Shared/Header/mbl/Corolla_Cross.png",
            link: "/corolla-cross",
          },
          {
            name: "RAV4",
            src: "https://sablobsitestoyotaprd.blob.core.windows.net/images/Shared/Header/mbl/RAV4.png",
            link: "/rav4",
          },
          {
            name: "SW4",
            src: "https://sablobsitestoyotaprd.blob.core.windows.net/images/Shared/Header/mbl/sw4_diamond.png",
            link: "/sw4",
          },
        ],
      },
      {
        name: "Esportivos",
        itens: [
          {
            name: "Corolla Hatch GR-Sport",
            src: "https://sablobsitestoyotaprd.blob.core.windows.net/images/Shared/Header/mbl/GR-Sport_Corolla_HB.png",
            link: "/corolla-hatch",
          },
          {
            name: "Corolla GR-Sport",
            src: "https://sablobsitestoyotaprd.blob.core.windows.net/images/Shared/Header/mbl/GR-Sport_Corolla.png",
            link: "/corolla?model=Esportivo",
          },
          {
            name: "Corolla Cross GR-Sport",
            src: "https://sablobsitestoyotaprd.blob.core.windows.net/images/Shared/Header/mbl/GR-Sport_Corolla_Cross.png",
            link: "/corolla-cross?model=Esportivo",
          },
          {
            name: "Hilux GR-Sport",
            src: "https://sablobsitestoyotaprd.blob.core.windows.net/images/Shared/Header/mbl/GR-Sport%20_HILUX.png",
            link: "/hilux?model=Esportivo",
          },
        ],
      },
      {
        name: "Híbridos",
        itens: [
          {
            name: "Corolla Híbrido",
            src: "https://sablobsitestoyotaprd.blob.core.windows.net/images/Shared/Header/mbl/Corolla_Altis_H%C3%ADbrido.png",
            link: "/corolla?model=Híbrido",
          },
          {
            name: "RAV4 Plug-in Híbrido",
            src: "https://sablobsitestoyotaprd.blob.core.windows.net/images/Shared/Header/mbl/Corolla_Cross_H%C3%ADbrido.png",
            link: "/rav4?model=Híbrido",
          },
          {
            name: "Corolla Cross Híbrido",
            src: "https://sablobsitestoyotaprd.blob.core.windows.net/images/Shared/Header/mbl/Corolla_Cross.png",
            link: "/corolla-cross?model=Híbrido",
          },
        ],
      },
      {
        name: "Pick-ups",
        itens: [
          {
            name: "Hilux Cabine Dupla",
            src: "https://sablobsitestoyotaprd.blob.core.windows.net/images/Shared/Header/mbl/HILUX_Conquest.png",
            link: "/hilux",
          },
        ],
      },
    ],
  },
  {
    name: "Seminovos",
    src: "https://sablobsitestoyotaprd.blob.core.windows.net/images/Shared/Header/seminovos.webp",
    groups: [
      {
        name: "Seminovos",
        itens: [
          {
            name: "Seminovos Certificados",
            link: `https://www.${BRAND_NAMES.LOWERCASE_PLAIN}seminovos.com.br/veiculos?certificado=true&ano=2018,2025&preco=4390000,42499000&km=101,81193&entrada=12749700&ordenacao=1`,
            target: "_blank",
          },
        ],
      },
      {
        name: "Site Seminovos",
        itens: [
          {
            name: "Ver estoque completo",
            link: `https://www.${BRAND_NAMES.LOWERCASE_PLAIN}seminovos.com.br`,
            target: "_blank",
          },
        ],
      },
    ],
  },
  {
    name: "Vendas Diretas",
    src: "https://sablobsitestoyotaprd.blob.core.windows.net/images/Shared/Header/venda_direta_branco.webp",
    groups: [
      {
        name: "",
        itens: [
          {
            name: "Frotista",
            link: "/venda-direta/frotista",
          },
          {
            name: "Pessoa com Deficiência",
            link: "/venda-direta/pessoa-com-deficiencia",
          },
          {
            name: "Produtor Rural",
            link: "/venda-direta/produtor-rural",
          },
          {
            name: "Taxista",
            link: "/venda-direta/taxista",
          },
          {
            name: "Governo",
            link: "/venda-direta/governo",
          },
        ],
      },
    ],
  },
  {
    name: "Serviços",
    src: "https://sablobsitestoyotaprd.blob.core.windows.net/images/Shared/Header/servicos.webp",
    groups: [
      {
        name: "Serviços",
        itens: [
          {
            name: "Pós-Vendas",
            link: "/servicos/pos-venda",
          },
          {
            name: "Garantia Toyota",
            link: "/garantiatoyota",
          },
          {
            name: "Agendamento",
            link: "/servicos/agendamento",
          },
        ],
      },
      {
        name: "Serviços de conveniência ao cliente (F&I)",
        itens: [
          {
            name: "Ciclo Toyota",
            link: "/servicos/ciclo-toyota",
          },
          {
            name: "Consórcio",
            link: "/servicos/consorcio",
          },
          {
            name: "Blindagem",
            link: "/servicos/blindagem",
          },
          /*{
            name: "Acessórios",
            link: "/servicos/acessorios",
          },*/
          {
            name: "Peças",
            link: "/servicos/pecas",
          },
        ],
      },
    ],
  },
  {
    name: "Sobre",
    src: "https://sablobsitestoyotaprd.blob.core.windows.net/images/Shared/Header/toyota_sobre.webp",
    groups: [
      {
        name: `Sobre a ${BRAND_NAMES.DEFAULT}`,
        itens: [
          {
            name: "História e Demonstrativos",
            link: "/sobre",
          },
          {
            name: "Serviço de Atendimento ao Consumidor",
            link: "",
            target: "_blank",
          },
          {
            name: "Trabalhe Conosco",
            link: "https://grupoaguiabranca.empregare.com/pt-br",
            target: "_blank",
          },
          {
            name: "Canal Compliance",
            link: "https://compliancedivisaocomercio.com.br",
            target: "_blank",
          },
          {
            name: "Portal de Privacidade",
            link: "https://privacidadecomercio.aguiabranca.com.br",
            target: "_blank",
          },
        ],
      },
    ],
  },
];
